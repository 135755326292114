import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import LoginTemplate from '@/templates/Login';
import { loginLead } from '@/services/auth.service';
import PropTypes from 'prop-types';
import { redirectTo } from 'utils/router';
import { setAuth } from '@/services/identity.service';
import { getCookieAuth } from 'utils/common';
import Head from 'next/head';
import useNotification from 'hooks/useNotification';
import { toastErrorMessage } from 'utils/message';

const Login = ({ initialMobileNumber, isBackOfficeUser, isChemist }) => {
    const [otp, setOtp] = useState('');
    const [isCheckedRememberMe, setIsCheckedRememberMe] = useState(true);
    const { notificationRef, showNotification } = useNotification();

    useEffect(() => {
        setAuth({ isRemember: isCheckedRememberMe });
    }, [isCheckedRememberMe]);

    const handleRememberMe = () => {
        setIsCheckedRememberMe((prev) => !prev);
    };

    const handleResend = () => {};

    const validationSchema = yup.object().shape({
        mobileNumber: yup
            .string()
            .required('Mobile number is required')
            .matches(/^\d{10}$/, 'Mobile number must be 10 digits'),
    });

    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            mobileNumber: initialMobileNumber || '',
        },
    });

    const onSubmit = async (data) => {
        const response = await loginLead({
            mobileNumber: '+91' + data.mobileNumber,
        });

        if (response.status) {
            const encodedSession = encodeURIComponent(
                response?.entity?.session
            );
            if (isBackOfficeUser || isChemist) {
                setAuth({ id: response?.entity?.user?.id });
                redirectTo(`/delivery-details`);
                return;
            }
            redirectTo(
                `/verify-otp?mobileNumber=${data.mobileNumber}&session=${encodedSession}&from=login`
            );
        } else {
            const shouldContinue = showNotification(
                response,
                'error',
                toastErrorMessage[response?.statusCode]
            );
            if (!shouldContinue) return;
            setError('mobileNumber', {
                type: 'manual',
                message:
                    response?.errorMessage === 'User Not Found' &&
                    `Mobile Number doesn't exist!`,
            });
        }
    };

    const handleMobileChange = (event) => {
        let inputValue = event.target.value;
        const maxLength = 10;

        if (inputValue.length > maxLength) {
            inputValue = inputValue.slice(0, maxLength);
            event.preventDefault();
        }

        event.target.value = inputValue;
    };
    const getJSONLD = () => {
        return {
            '@context': 'https://schema.org',
            '@type': 'LoginAction',
            target: {
                '@type': 'EntryPoint',
                urlTemplate: 'https://yourdomain.com/login',
                inLanguage: 'en-US',
                actionPlatform: [
                    'http://schema.org/DesktopWebPlatform',
                    'http://schema.org/TabletWebPlatform',
                    'http://schema.org/MobileWebPlatform',
                ],
            },
            result: {
                '@type': 'Person',
                telephone: initialMobileNumber,
            },
        };
    };

    return (
        <>
            <Head>
                <title>Login</title>
                <meta
                    name="description"
                    content="Login to Your Website with your mobile number to access your account. Receive an OTP for verification."
                />
                <meta
                    property="og:url"
                    content="https://psoriasis-stage-website.svaas.com/login"
                />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Login to Your Website" />
                <meta
                    property="og:description"
                    content="Login to Your Website with your mobile number to access your account. Receive an OTP for verification."
                />
                <meta
                    property="og:image"
                    content="http://example.com/path/to/your/image.jpg"
                />
                <script type="application/ld+json">
                    {JSON.stringify(getJSONLD())}
                </script>
            </Head>
            <LoginTemplate
                register={register}
                setValue={setValue}
                getValues={getValues}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                errors={errors}
                handleRememberMe={handleRememberMe}
                isCheckedRememberMe={isCheckedRememberMe}
                handleMobileChange={handleMobileChange}
                handleResend={handleResend}
                otpSent={false}
                otp={otp}
                setOtp={setOtp}
                isBackOfficeOrChemistUser={isBackOfficeUser || isChemist}
                notificationRef={notificationRef}
            />
        </>
    );
};

Login.propTypes = {
    initialMobileNumber: PropTypes.string,
    isBackOfficeUser: PropTypes.bool,
    isChemist: PropTypes.bool,
};
export default Login;

export async function getServerSideProps(ctx) {
    const mobileNumber = ctx.query.mobileNumber || '';

    if (
        getCookieAuth(ctx) &&
        getCookieAuth(ctx).id_token &&
        getCookieAuth(ctx).id
    ) {
        return {
            redirect: {
                destination: '/delivery-details',
                permanent: false,
            },
        };
    }
    const auth = getCookieAuth(ctx);
    const isBackOfficeUser = !!auth?.backOfficeUserId || false;
    const isChemist = !!auth?.chemistId || false;

    return {
        props: {
            initialMobileNumber: mobileNumber,
            isBackOfficeUser,
            isChemist,
        },
    };
}

import PropTypes from 'prop-types';
import Layout from 'components/Layout';
import Login from '@/organisms/Login';
import Toast from 'components/Toast';

const LoginTemplate = ({
    register,
    setValue,
    getValues,
    handleSubmit,
    onSubmit,
    errors,
    handleRememberMe,
    isCheckedRememberMe,
    handleMobileChange,
    handleResend,
    otpSent,
    otp,
    setOtp,
    isBackOfficeOrChemistUser,
    notificationRef,
}) => {
    return (
        <Layout
            showFooter={false}
            showHeader={!isBackOfficeOrChemistUser}
            showlogoStrip={!isBackOfficeOrChemistUser}
        >
            <Toast ref={notificationRef} />
            <Login
                register={register}
                setValue={setValue}
                getValues={getValues}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                errors={errors}
                handleRememberMe={handleRememberMe}
                isCheckedRememberMe={isCheckedRememberMe}
                handleMobileChange={handleMobileChange}
                handleResend={handleResend}
                otp={otp}
                setOtp={setOtp}
                otpSent={otpSent}
                isBackOfficeOrChemistUser={isBackOfficeOrChemistUser}
            />
        </Layout>
    );
};

LoginTemplate.defaultProps = {
    otpSent: false,
};

LoginTemplate.propTypes = {
    register: PropTypes.func,
    setValue: PropTypes.func,
    getValues: PropTypes.func,
    handleSubmit: PropTypes.func,
    onSubmit: PropTypes.func,
    errors: PropTypes.object,
    handleRememberMe: PropTypes.func,
    isCheckedRememberMe: PropTypes.bool,
    handleMobileChange: PropTypes.func,
    handleResend: PropTypes.func,
    otpSent: PropTypes.bool,
    otp: PropTypes.string,
    setOtp: PropTypes.func,
    isBackOfficeOrChemistUser: PropTypes.bool,
    notificationRef: PropTypes.object,
};

export default LoginTemplate;

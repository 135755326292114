import * as HttpService from './http.service';
import {
    LOGIN_LEAD_URL,
    SEND_CHEMIST_OTP_URL,
    SIGNUP_LEAD_URL,
    VERIFY_CHEMIST_OTP_URL,
    VERIFY_OTP_URL,
} from './url.service';

export const signupLead = (data) => {
    return HttpService.postWithOutAuth(SIGNUP_LEAD_URL, data);
};

export const loginLead = (data) => {
    return HttpService.postWithOutAuth(LOGIN_LEAD_URL, data);
};

export const verifyOtp = (data) => {
    return HttpService.postWithOutAuth(VERIFY_OTP_URL, data);
};

export const sendChemistOtpService = ({ mobileNumber }) => {
    return HttpService.postWithOutAuth(SEND_CHEMIST_OTP_URL, { mobileNumber });
};

export const verifyChemistOtpService = ({ mobile, session, code }) => {
    return HttpService.postWithOutAuth(VERIFY_CHEMIST_OTP_URL, {
        mobile,
        session,
        code,
    });
};

import PropTypes from 'prop-types';
import Button from '@/atoms/Button';
import Checkbox from '@/atoms/Checkbox';
import Heading from '@/atoms/Heading';
import InputBox from '@/atoms/InputBox';
import Text from '@/atoms/Text';
import Image from 'next/image';
import Link from 'next/link';

const Login = ({
    register,
    setValue,
    getValues,
    handleSubmit,
    onSubmit,
    errors,
    handleRememberMe,
    isCheckedRememberMe,
    handleMobileChange,
    isBackOfficeOrChemistUser,
}) => {
    return (
        <>
            <div className="bg-grey-shade6 py-5 md:py-14 ">
                <div className="container">
                    <div className="flex flex-wrap bg-white rounded-lg pt-6 pb-8 md:py-10 px-4 md:px-10 xl:px-[100px] border border-grey-shade5">
                        <div className="w-full lg:w-5/12 lg:border-r border-grey-shade5 lg:pr-10">
                            <Heading
                                type="h2"
                                fontSize="text-4xl/[38px]"
                                fontFamily="font-sectionHeading"
                                fontColor="text-grey-shade1"
                                className=" hidden lg:block"
                            >
                                {isBackOfficeOrChemistUser
                                    ? 'Please enter customer details'
                                    : 'Please enter your details'}
                            </Heading>
                            <Heading
                                type="h2"
                                fontSize="text-4xl/[38px]"
                                fontFamily="font-sectionHeading"
                                className="text-grey-shade1 block lg:hidden"
                            >
                                Login with your mobile number
                            </Heading>
                            {!isBackOfficeOrChemistUser && (
                                <Text
                                    variant="body2"
                                    className="hidden lg:block"
                                >
                                    Share a few details about yourself to get
                                    started
                                </Text>
                            )}

                            <Image
                                src="/images/login_img.svg"
                                width={380}
                                height={376}
                                alt="login-hero-image"
                                className="mx-auto lg:mx-0 max-w-[140px] md:max-w-[180px] lg:max-w-[240px] xl:max-w-max mt-6 md:mt-8 mb-6 lg:my-6"
                            />
                        </div>
                        <div className="relative w-full max-w-[420px] lg:max-w-full lg:w-7/12 pl-0 lg:pl-20 mx-auto md:min-h-[446px] lg:min-h-[518px]">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Heading
                                    type="h4"
                                    fontFamily="font-body"
                                    className="mb-5 hidden lg:block"
                                    fontWeight="font-medium"
                                >
                                    {isBackOfficeOrChemistUser
                                        ? 'Login with customer mobile number'
                                        : 'Login with your mobile number'}
                                </Heading>

                                <InputBox
                                    id="mobileNumber"
                                    labelClass="font-medium"
                                    name="mobileNumber"
                                    type="tel"
                                    isLabel={true}
                                    labelText="Enter Registered Mobile Number"
                                    placeholder=""
                                    register={register}
                                    dbName={'mobileNumber'}
                                    maxLength={10}
                                    errorMessage={
                                        errors.mobileNumber &&
                                        errors.mobileNumber?.message
                                    }
                                    isRequired={true}
                                    isError={errors.mobileNumber ? true : false}
                                    getValues={getValues}
                                    onChange={(e) => {
                                        const numericValue =
                                            e.target.value.replace(
                                                /[^0-9]/g,
                                                ''
                                            );
                                        return setValue(
                                            'mobileNumber',
                                            numericValue
                                        );
                                    }}
                                    onInput={handleMobileChange}
                                />
                                {!isBackOfficeOrChemistUser && (
                                    <Checkbox
                                        containerClass=" w-full mt-6 md:mt-8"
                                        checkBoxLabel="Remember Me"
                                        handleChange={handleRememberMe}
                                        isSelected={isCheckedRememberMe}
                                    />
                                )}

                                <div className="flex flex-col md:flex-row flex-wrap gap-2 md:gap-5 justify-center lg:justify-start md:absolute bottom-0 mt-[73px] md:mt-0">
                                    <Button
                                        label="Login"
                                        size="default"
                                        type="submit"
                                    />
                                    <Link href="/signup">
                                        <Button
                                            label="Create an account"
                                            style="ghost"
                                            size="default"
                                        />
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

Login.defaultProps = {};

Login.propTypes = {
    register: PropTypes.func,
    setValue: PropTypes.func,
    getValues: PropTypes.func,
    handleSubmit: PropTypes.func,
    onSubmit: PropTypes.func,
    errors: PropTypes.object,
    handleRememberMe: PropTypes.func,
    isCheckedRememberMe: PropTypes.bool,
    handleMobileChange: PropTypes.func,
    isBackOfficeOrChemistUser: PropTypes.bool,
};

export default Login;

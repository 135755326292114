import React, { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Toast = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        displayToast(
            type = 'info',
            message = ['This is a toast'],
            autoClose = '5000',
            position = 'top-right',
            closeOnClick = true
        ) {
            if (typeof message === 'string') {
                message = [message];
            }
            message.forEach((msg) => {
                toast[type](msg, {
                    position,
                    autoClose,
                    closeOnClick,
                });
            });
        },
    }));
    return (
        <>
            <ToastContainer />
        </>
    );
});

Toast.displayName = 'Toast';

Toast.propTypes = {
    type: PropTypes.string,
    message: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
    autoClose: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    position: PropTypes.string,
    closeOnClick: PropTypes.bool,
};

export default Toast;
